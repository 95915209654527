/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/law%2F3b405363e1b07ef2e8ec624a0dcb054.jpg",
    alt: "农产品地理标志使用证书"
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/law%2F5f585baf2aaf08ada72638682edaf9c.png",
    alt: "森林生态标志产品证书"
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/law%2F99ca77393d6aa1b713e881fc2e5750e.jpg",
    alt: "绿色食品证书"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
